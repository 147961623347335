<template>
	<screen class="categories-view" size="large">
		<guest-template
			slot="content"
			navHeaderTitle="Find a Nonprofit"
			:showNavHeader="true"
			:showNavFooter="false"
			:divider="true"
		>
			<v-container>
				<SearchCategories
					:max="null"
					defaultShow="true"
					@click="onCategoryClick"
					breakpoint="lg"
				/>
			</v-container>
		</guest-template>
	</screen>
</template>

<script>
import guestTemplate from '../../templates/guestTemplate.vue'
import Screen from '../../ui/Screen.vue'
export default {
	components: { guestTemplate, Screen },
	name: 'CategoriesView',

	data: () => ({}),
	methods: {
		onCategoryClick(cat) {
			this.$router.push({
				name: 'search.results',
				params: { category: cat.title }
			})
		}
	}
}
</script>
<style lang="scss">
.categories-view {
	padding: 0px !important;
	> .screen-sub,
	> .screen-body {
		display: none;
		margin: 0;
	}
	hr {
		width: 100%;
		border: 1px solid #dddee3;
	}
}
</style>
